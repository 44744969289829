export interface OrderAdjustingInputField {
    amount: number;
    description: string;
    card_type: string;  
}

export const AMOUNT_PROPERTY = 'amount'

export const iniOrderAdjustingInputField = (): OrderAdjustingInputField => {
    return {
        amount: 0,
        description: '',
        card_type:''

    }
}