import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MemberGroupForm from '../../../common/MemberGroupForm/MemberGroupForm';
import { IMemberGroup } from '../../../../models/IMemberGroups';
import { toast } from '../../../common/SnackBar/snackBar';
import { createNewMemberGroupsAPI } from '../../../../api/MemberGroupAPI';
import MemberGroupLayoutPage from './MemberGroupLayoutPage';

const defaultMemberGroup: IMemberGroup = {
  id: 0,
  name: '',
  created_at: '',
  updated_at: '',
  tenant_id: 0,
  slug: '',
  is_active: true,
  is_global: false,
  is_deleted: false,
  deleted_at: null,
  created_by: 0,
  updated_by: 0,
  deleted_by: null,
  description: '',
  referralCode:'',
  user_ids: [],
};

const CreateMemberGroupPage: React.FC = () => {
  const [memberGroupForm] = useState<IMemberGroup>(defaultMemberGroup);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSave = async (memberGroup: IMemberGroup) => {
    setIsLoading(true);
    try {
      await createNewMemberGroupsAPI(memberGroup);
      toast.success('Member group created successfully');
      navigate('/System/MemberGroups');
    } catch (error) {
      toast.error('Error creating member group');
    } finally {
    }
  };

  const handleCancel = () => {
    navigate('/System/MemberGroup');
  };

  const title = 'Create Member Group';
  const backLink = '/System/MemberGroups';

  const tabs = [
    {
      label: 'General',
      content: (
        <MemberGroupForm
          memberGroupForm={memberGroupForm}
          onSave={handleSave}
          onCancel={handleCancel}
          isLoading={isLoading}
  
        />
      ),
    },
  ];

  return (
    <div>
      <MemberGroupLayoutPage title={title} backLink={backLink} tabs={tabs} />
    </div>
  );
};

export default CreateMemberGroupPage;
