export interface UserAdjustingInputField {
    amount: number;
    description: string;
    card_type: string;  
}

export const AMOUNT_PROPERTY = 'amount'

export const iniUserAdjustingInputField = (): UserAdjustingInputField => {
    return {
        amount: 0,
        description: '',
        card_type:''
    }
}