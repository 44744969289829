import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Divider, Typography } from '@mui/material';
import { UserGeneralInfoProps } from '../../../../models/IUser';
import { IStoreCreditWallet, StoreCreditTransactionProps, UserStoreCreditProps } from '../../../../models/IStoreCredit';
import SendSms from './component/SendSms';
import { sentSmsAPI, userAdjustWalletAPI } from '../../../../api/UserAPI';
import { toast } from '../../../common/SnackBar/snackBar';
import UserAdjustWallet from './component/UserAdjustWallet';
import { UserAdjustingInputField } from './component/UserAdjustmentDto';
import { RewardPointInputField } from './component/UserAddRewardPointDto';
import UserRewardPoints from './component/UserAddRewardPoints';
import { userAdjustRewardPointAPI } from '../../../../api/RewardPointsAPI';
import useSWR from 'swr';
import { getUserWalletInfo,  } from '../../../../api/UserWalletInfoAPI';
import { getStoreCreditByUserIdAPI } from '../../../../api/StoreCreditAPI';

const formatBalance = (balance: number | undefined) => {
  if (balance === undefined) return '$0.00';
  const formatter = new Intl.NumberFormat('en-SG', {
    style: 'currency',
    currency: 'SGD',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
  });
  return balance < 0 ? `-${formatter.format(Math.abs(balance))}` : formatter.format(balance);
};

const UserGeneralInfo: React.FC<UserGeneralInfoProps & UserStoreCreditProps & StoreCreditTransactionProps> = ({
  user,
  storeCredit,
  userId
}) => {
  const [openSms, setOpenSms] = useState(false);
  const [openAdjustWallet, setOpenAdjustWallet] = useState(false);
  const [openRewardPoint, setOpenRewardPoint] = useState(false);
  const [storeCredits, setStoreCredits] = useState<IStoreCreditWallet[]>([]); // Define storeCredits state

  useEffect(() => {
    const fetchStoreCredits = async () => {
      try {
        const data = await getStoreCreditByUserIdAPI(userId!); // Fetch store credits for the user
        setStoreCredits(data.result.items); // Set the storeCredits state
      } catch (error) {
        console.error('Failed to fetch store credits:', error);
      }
    };

    if (userId) fetchStoreCredits(); // Fetch data only if userId is available
  }, [userId]);

  const { data: walletAmount } = useSWR(
    userId ? `getUserWalletInfo/${userId}` : null,
    () => getUserWalletInfo(userId!).then((response) => {
      return response;
    })
  );

  const _sentSms = async (message: string) => {
    const payload = {
      customer_id: user?.id,
      message: message,
    };
    try {
      const response = await sentSmsAPI(payload);
      toast.success("SMS sent successful!");
      console.log('SMS sent successful!:', response);
    } catch (error) {
      toast.success("SMS sent Fail!");
      console.error('SMS sent Fail!:', error);
    }
  };

  const _updateAdjustWallet = async (inputField: UserAdjustingInputField) => {
    const payload = {
      customer_id: user?.id,
      amount: inputField.amount,
      description: inputField.description,
      card_type:inputField.card_type,
    };
    try {
      const response = await userAdjustWalletAPI(payload);
      toast.success("Adjust Wallet successful!");
      console.log('Wallet deduction response:', response);
    } catch (error) {
      toast.success("Adjust Wallet Fail!");
      console.error('Error deducting wallet:', error);
    }
  };

  const _updateRewardPoint = async (inputField: RewardPointInputField) => {
    const payload = {
        user_id: user?.id,
        points: inputField.points,
        description: inputField.description,
    };
    try {
        const response = await userAdjustRewardPointAPI(payload);
        toast.success('Reward Points updated successfully!');
        console.log('Reward points response:', response);
    } catch (error) {
        toast.error('Failed to update reward points!');
        console.error('Error updating reward points:', error);
    }
};

  return (
    <>
      <SendSms
        open={openSms}
        setOpen={setOpenSms}
        sendSms={_sentSms}
      />
      <UserAdjustWallet
        open={openAdjustWallet}
        setOpen={setOpenAdjustWallet}
        updateAdjustWallet={_updateAdjustWallet}
        storeCredits={storeCredits}
      />
      <UserRewardPoints
        open={openRewardPoint}
        setOpen={setOpenRewardPoint}
        updateRewardPoints={_updateRewardPoint}
      />
    
      <Container sx={{ maxWidth: '400px', padding: 0, backgroundColor: '#ffffff', borderRadius: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 3 }}>
          <Typography component="span" variant="h6" gutterBottom>
            {`${user?.firstname} ${user?.lastname}`}
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
            Credit Card Linked: {walletAmount?.has_credit_card ? '✅' : '❌'}
          </Typography>
        </Box>

        <Divider sx={{ width: '100%', marginBottom: 3 }} />

        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component="span" variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}>
              Balance:
            </Typography>
            <Typography component="span" variant="body1" sx={{ flex: 1 }}>
              {formatBalance(walletAmount?.wallet_amount)}
            </Typography>
          </Box>
          <Divider sx={{ marginY: 1 }} />
        </Box>

        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component="span" variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}>
              Email:
            </Typography>
            <Typography component="span" variant="body1" sx={{ flex: 1 }}>
              {user?.email}
            </Typography>
          </Box>
          <Divider sx={{ marginY: 1 }} />
        </Box>

        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component="span" variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}>
              Mobile No:
            </Typography>
            <Typography component="span" variant="body1" sx={{ flex: 1 }}>
              {user?.full_mobile || `${user?.country_code} ${user?.mobile}`}
            </Typography>
          </Box>
          <Divider sx={{ marginY: 1 }} />
        </Box>

        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component="span" variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}>
              Birth Year:
            </Typography>
            <Typography component="span" variant="body1" sx={{ flex: 1 }}>
              {user?.birth_year}
            </Typography>
          </Box>
          <Divider sx={{ marginY: 1 }} />
        </Box>

        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component="span" variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}>
              Birth Month:
            </Typography>
            <Typography component="span" variant="body1" sx={{ flex: 1 }}>
              {user?.birth_month}
            </Typography>
          </Box>
          <Divider sx={{ marginY: 1 }} />
        </Box>

        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component="span" variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}>
              Email Consent:
            </Typography>
            <Typography component="span" variant="body1" sx={{ flex: 1 }}>
              {user?.email_consent ? '✔️' : '❌'}
            </Typography>
          </Box>
          <Divider sx={{ marginY: 1 }} />
        </Box>

        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component="span" variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}>
              SMS Consent:
            </Typography>
            <Typography component="span" variant="body1" sx={{ flex: 1 }}>
              {user?.sms_consent ? '✔️' : '❌'}
            </Typography>
          </Box>
          <Divider sx={{ marginY: 1 }} />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
          <Button sx={{ marginRight: 1 }} variant="contained" color="primary" onClick={() => setOpenAdjustWallet(true)}>
            Adjust Wallet
          </Button>
          <Button sx={{ marginRight: 1 }} variant="contained" color="primary" onClick={() => setOpenSms(true)}>
            Send SMS
          </Button>
          <Button sx={{ marginRight: 1 }} variant="contained" color="primary" onClick={() => setOpenRewardPoint(true)}>
            Add Reward Points
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default UserGeneralInfo;
