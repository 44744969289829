import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { getStoreCreditByUserIdAPI } from '../../../../api/StoreCreditAPI';
import { IStoreCreditWallet, StoreCreditWalletResponse } from '../../../../models/IStoreCredit';

const StoreCreditWallet: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const userId = id ? parseInt(id, 10) : null;

  const [storeCredits, setStoreCredits] = useState<IStoreCreditWallet[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchStoreCredits = async () => {
      try {
        setLoading(true);
        const data: StoreCreditWalletResponse = await getStoreCreditByUserIdAPI(userId!);
        setStoreCredits(data.result.items);
      } catch (error) {
        setError(`Failed to fetch store credits for user ID ${userId}`);
      } finally {
        setLoading(false);
      }
    };
    
    if (userId) fetchStoreCredits();
  }, [userId]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="store credit wallet table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="right">Balance</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Card Type</TableCell>
            <TableCell align="right">Latest Amount</TableCell>
            <TableCell align="right">Expiry</TableCell>
            <TableCell align="right">Valid From</TableCell>
            <TableCell align="right">Created At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {storeCredits && storeCredits.length > 0 ? (
            storeCredits.map((credit) => (
              <TableRow key={credit.id}>
                <TableCell>{credit.id}</TableCell>
                <TableCell align="right">{credit.balance}</TableCell>
                <TableCell align="right">{credit.type || 'N/A'}</TableCell>
                <TableCell align="right">{credit.card_type || 'N/A'}</TableCell>
                <TableCell align="right">{credit.latest_amount || 'N/A'}</TableCell>
                <TableCell align="right">{credit.expiry ? dayjs(credit.expiry).format('DD-MM-YYYY') : 'N/A'}</TableCell>
                <TableCell align="right">{credit.valid_from ? dayjs(credit.valid_from).format('DD-MM-YYYY') : 'N/A'}</TableCell>
                <TableCell align="right">{dayjs(credit.created_at).format('DD-MM-YYYY HH:mm')}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No store credit records found for this user.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StoreCreditWallet;