import { PATH_NAME } from '../configs/pathName';
import axiosInstance from './axiosInstance';
import { handleError } from '../helpers/Fetchers/fetchers';
import { IUserRoleAPIResponse } from '../models/IUserRole';

export const userRoleAPI = async (): Promise<IUserRoleAPIResponse> => {
  try {
      const response = await axiosInstance.get<IUserRoleAPIResponse>(PATH_NAME.API_GET_USER_ROLES);
      return response.data;
  } catch (error) {
      handleError(error, "fetching roles");
      throw error;
  }
};