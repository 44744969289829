import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { DataTableProps } from '../../../models/IDataTable';
import { Box, CircularProgress } from '@mui/material';

interface Props extends DataTableProps {
  pageSizeOptions?: number[];
  initialPageSize?: number;
}

const DataTable: React.FC<Props> = ({
  rows,
  columns,
  loading,
  getRowId,
  checkboxSelection = false,
  pageSizeOptions = [100], // Default to 100 if not provided
  initialPageSize = 100, // Default page size
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: initialPageSize },
            },
          }}
          pageSizeOptions={pageSizeOptions}
          checkboxSelection={checkboxSelection}
          sx={{
            backgroundColor: '#fff',
            boxShadow: 3,
            borderRadius: '8px',
            border: '1px solid #e0e0e0',
            padding: 2,
          }}
        />
      )}
    </Box>
  );
};

export default DataTable;
