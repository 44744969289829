import MarketingPageLayout from "../components/features/Marketing/MarketingPageLayout";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import RedeemOutlinedIcon from "@mui/icons-material/RedeemOutlined";
import { IRouteType } from "../models/IRouteType";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import PaymentIcon from "@mui/icons-material/Payment";
import SystemPageLayout from "../components/features/System/SystemPageLayout";
import UserRole from "../components/features/System/UserRole/UserRole";
import AddRolePage from "../components/features/System/UserRole/AddRolePage";
import EditRolePage from "../components/features/System/UserRole/EditRolePage";
import Vouchers from "../components/features/Marketing/CouponDefinitions/Vouchers";
import CreateVoucherPage from "../components/features/Marketing/CouponDefinitions/CreateVoucherPage";
import EditVoucherPage from "../components/features/Marketing/CouponDefinitions/EditVoucherPage";
import MemberGroup from "../components/features/System/MemberGroup/MemberGroups";
import AddMemberGroup from "../components/features/System/MemberGroup/AddMemberGroup";
import EditMemberGroupPage from "../components/features/System/MemberGroup/EditMemberGroup";
import CreateMemberGroupCoupon from "../components/features/Marketing/MemberGroupCoupons/CreateMemberGroupCoupon";
import MemberGroupCouponTable from "../components/features/Marketing/MemberGroupCoupons/MemberGroupCouponTable";
import EditMemberGroupCoupon from "../components/features/Marketing/MemberGroupCoupons/EditMemberGroupCoupon";
import UserCouponTable from "../components/features/Marketing/UserCoupons/UserCouponTable";
import CreateUserCoupon from "../components/features/Marketing/UserCoupons/CreateUserCoupon";
import EditUserCoupon from "../components/features/Marketing/UserCoupons/EditUserCoupon";
import RewardPointTable from "../components/features/RewardPoints/RewardPoints";
import CreateRewardPointPage from "../components/features/RewardPoints/CreateRewardPointPage";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import OrderTable from "../components/features/History/Orders/OrderTable";
import OrderDetailsPage from "../components/features/History/Orders/OrderDetailsPage";
import PaymentTable from "../components/features/History/Payments/PaymentTable";
import PaymentDetailsPage from "../components/features/History/Payments/PaymentDetailsPage";
import UserDetailsPage from "../components/features/System/Users/UserDetailsPage";
import SearchUserTable from "../components/features/System/Users/SearchUserTable";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import Collections from "../components/features/ProductManagement/Collection/Collection";
import AddCollection from "../components/features/ProductManagement/Collection/AddCollection";
import InventoryIcon from "@mui/icons-material/Inventory";
import Sku from "../components/features/ProductManagement/Sku/Sku";
import SkuDetailsPage from "../components/features/ProductManagement/Sku/EditSku/EditSkuDetailsPage";
import HomePageContent from "../components/features/Content/HomePageContent";
import EditCollection from "../components/features/ProductManagement/Collection/EditCollection";
import AddSkuDetailsPage from "../components/features/ProductManagement/Sku/AddSku/AddSkuDetailsPage";
import BannerListPage from "../components/features/Content/Banners";
import VideoList from "../components/features/Content/Video/VideoList";
import AnouncementList from "../components/features/Content/Anouncement/AnnouncementList";
import TasteBudList from "../components/features/Content/TasteBud/TasteBudList";
import BannerAdd from "../components/features/Content/Banner/BannerAdd";
import BannerEdit from "../components/features/Content/Banner/BannerEdit";
import VideoAdd from "../components/features/Content/Video/VideoAdd";
import VideoEdit from "../components/features/Content/Video/VideoEdit";
import AnnouncementAdd from "../components/features/Content/Anouncement/AnnouncementAdd";
import AnnouncementEdit from "../components/features/Content/Anouncement/AnnouncementEdit";
import TasteBudAdd from "../components/features/Content/TasteBud/TasteBudAdd";
import TasteBudEdit from "../components/features/Content/TasteBud/TasteBudEdit";
import BadgeList from "../components/features/ProductManagement/Badge/BadgeList";
import AddBadge from "../components/features/ProductManagement/Badge/AddBadge";
import EditBadge from "../components/features/ProductManagement/Badge/EditBadge";
import GetAllButtonLink from "../components/features/ButtonLink/GetAllButtonLink";
import AddButtonLink from "../components/features/ButtonLink/AddButtonLink";
import EditButtonLinkPage from "../components/features/ButtonLink/EditButtonLink";
import LinkIcon from '@mui/icons-material/Link';
import GetAllContactForm from "../components/features/ContactForm/GetAllContactForm";
import EditContactFormPage from "../components/features/ContactForm/EditContactForm";
import ArchiveIcon from '@mui/icons-material/Archive';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PartnerList from "../components/features/PartnerLogo/PartnerList";
import AddPartnerLogo from "../components/features/PartnerLogo/AddPartner";
import RetrieveOtp from "../components/features/RetrieveOTP/RetrieveOTP";
import PasswordIcon from '@mui/icons-material/Password';

const appRoutes: IRouteType[] = [
  {
    path: "/Marketing",
    element: <MarketingPageLayout />,
    state: "Marketing",
    sidebarProps: {
      displayText: "Marketing",
      icon: <CampaignOutlinedIcon />,
    },
    child: [
      {
        path: "/Marketing/CouponDefinitions",
        element: <Vouchers />,
        state: "Marketing.Vouchers.Vouchers",
        sidebarProps: {
          displayText: "Coupon Definitions",
          icon: <RedeemOutlinedIcon />,
        },
      },
      {
        path: "/Marketing/MemberGroupCoupons",
        element: <MemberGroupCouponTable />,
        state: "Marketing.Vouchers.MemberGroupCouponTable",
        sidebarProps: {
          displayText: "Member Group Coupons",
          icon: <RedeemOutlinedIcon />,
        },
      },
      {
        path: "/Marketing/UserCoupons",
        element: <UserCouponTable />,
        state: "Marketing.Vouchers.UserCoupons",
        sidebarProps: {
          displayText: "User Coupons",
          icon: <RedeemOutlinedIcon />,
        },
      },
      {
        path: "/Marketing/RewardPoint",
        element: <RewardPointTable />,
        state: "Marketing.RewardPoint",
        sidebarProps: {
          displayText: "Reward Point",
          icon: <RedeemOutlinedIcon />,
        },
      },
      {
        path: "/Marketing/RewardPoint/AddRewardPoint/",
        element: <CreateRewardPointPage />,
        state: "Marketing.RewardPoint.AddRewardPoint",
      },
      {
        path: "/Marketing/Vouchers/EditCouponDefinitions/:id",
        element: <EditVoucherPage />,
        state: "Marketing.Vouchers.EditCouponDefinitions",
      },
      {
        path: "/Marketing/Vouchers/CreateCouponDefinitions/",
        element: <CreateVoucherPage />,
        state: "Marketing.Vouchers.CreateCouponDefinitions",
      },
      {
        path: "/Marketing/Vouchers/CreateMemberGroupCoupons/",
        element: <CreateMemberGroupCoupon />,
        state: "Marketing.Vouchers.CreateMemberGroupCoupons",
      },
      {
        path: "/Marketing/Vouchers/EditMemberGroupCoupons/:id",
        element: <EditMemberGroupCoupon />,
        state: "Marketing.Vouchers.EditMemberGroupCoupon",
      },
      {
        path: "/Marketing/Vouchers/CreateUserCoupons/",
        element: <CreateUserCoupon />,
        state: "Marketing.Vouchers.CreateUserCoupons",
      },
      {
        path: "/Marketing/Vouchers/EditUserCoupons/:id",
        element: <EditUserCoupon />,
        state: "Marketing.Vouchers.EditUserCoupons",
      },
    ],
  },
  {
    path: "/System",
    element: <SystemPageLayout />,
    state: "System",
    sidebarProps: {
      displayText: "System",
      icon: <SettingsIcon />,
    },
    child: [
      {
        path: "/System/Roles",
        element: <UserRole />,
        state: "System.Roles",
        sidebarProps: {
          displayText: "Roles",
          icon: <PersonIcon />,
        },
      },
      {
    path: "/System/users",
    element: <SearchUserTable />,
    state: "System.Users",
    sidebarProps: {
      displayText: "Users",
      icon: <PersonIcon />,
    },
  },
      {
        path: "/System/Roles/AddRole",
        element: <AddRolePage />,
        state: "System.Roles.AddRole",
      },
      {
        path: "/System/Roles/EditRoles/:id",
        element: <EditRolePage />,
        state: "System.Roles.EditRoles",
      },
      {
        path: "/System/MemberGroups",
        element: <MemberGroup />,
        state: "System.MemberGroups",
        sidebarProps: {
          displayText: "Member Groups",
          icon: <PersonIcon />,
        },
      },
      {
        path: "/System/MemberGroups/AddMemberGroups",
        element: <AddMemberGroup />,
        state: "System.MemberGroups.AddMemberGroups",
      },
      {
        path: "/System/MemberGroups/EditMemberGroups/:id",
        element: <EditMemberGroupPage />,
        state: "System.MemberGroups.EditMemberGroups",
      },
    ],
    
  },
  
  {
    path: "/users/user_details/:id",
    element: <UserDetailsPage />,
    state: "Users.EditUsers",
  },
  {
    path: "order_details/:orderId",
    element: <OrderDetailsPage />,
    state: "OrderDetails",
  },
  {
    path: "payment_details/:paymentId",
    element: <PaymentDetailsPage />,
    state: "PaymentDetails",
  },
  {
    path: "/Content",
    element: <MarketingPageLayout />,
    state: "Content",
    sidebarProps: {
      displayText: "Content Management",
      icon: <DesktopWindowsOutlinedIcon />,
    },
    child: [
      {
        path: "/Content/HomePageContent",
        element: <HomePageContent />,
        state: "Content.HomePageContent",
        sidebarProps: {
          displayText: "Home Page Content",
          icon: <DesktopWindowsOutlinedIcon />,
        },
        
        
        child: [
          {
            path: "/Content/HomePageContent/banner",
            element: <BannerListPage />,
            state: "Content.HomePageContent.banner",
          },
          {
            path: "/Content/HomePageContent/banner/add",
            element: <BannerAdd />,
            state: "Content.HomePageContent.banner.add",
          },
          {
            path: "/Content/HomePageContent/banner/edit/:id",
            element: <BannerEdit />,
            state: "Content.HomePageContent.banner.edit",
          },
          {
            path: "/Content/HomePageContent/video",
            element: <VideoList />,
            state: "Content.HomePageContent.video",
          },
          {
            path: "/Content/HomePageContent/video/add",
            element: <VideoAdd />,
            state: "Content.HomePageContent.video.add",
          },
          {
            path: "/Content/HomePageContent/video/edit/:id",
            element: <VideoEdit />,
            state: "Content.HomePageContent.video.edit",
          },
          {
            path: "/Content/HomePageContent/announcement",
            element: <AnouncementList />,
            state: "Content.HomePageContent.announcement",
          },
          {
            path: "/Content/HomePageContent/announcement/add",
            element: <AnnouncementAdd />,
            state: "Content.HomePageContent.announcement.add",
          },
          {
            path: "/Content/HomePageContent/announcement/edit/:id",
            element: <AnnouncementEdit />,
            state: "Content.HomePageContent.announcement.edit",
          },
          {
            path: "/Content/HomePageContent/tastebud",
            element: <TasteBudList />,
            state: "Content.HomePageContent.tastebud",
          },
          {
            path: "/Content/HomePageContent/tastebud/add",
            element: <TasteBudAdd />,
            state: "Content.HomePageContent.tastebud.add",
          },
          {
            path: "/Content/HomePageContent/tastebud/edit/:id",
            element: <TasteBudEdit />,
            state: "Content.HomePageContent.tastebud.edit",
          },
        ],
      },
      {
        path: "/Content/HomePageContent/contact_form",
        element: <GetAllContactForm />,
        state: "Content.HomePageContent.ContactForm",
        sidebarProps: {
          displayText: "Contact Form",
          icon: <ArchiveIcon />,
        }
      },
      {
        path: "/Content/HomePageContent/contact_form/edit/:id",
        element: <EditContactFormPage />,
        state: "Content.HomePageContent.Contact_form.edit",
      },
      {
        path: "/Content/HomePageContent/partners",
        element: <PartnerList />,
        state: "Content.HomePageContent.Partners",
        sidebarProps: {
          displayText: "Partners",
          icon: <HandshakeIcon />,
        }
      },
      {
        path: "/Content/HomePageContent/partners/add",
        element: <AddPartnerLogo />,
        state: "Content.HomePageContent.Partners.add",
      },
    ],
    
  },

  {
    path: "/product",
    element: <MarketingPageLayout />,
    state: "Product",
    sidebarProps: {
      displayText: "Product Management",
      icon: <InventoryIcon />,
    },
    child: [
      {
        path: "/product/collections",
        element: <Collections />,
        state: "Product.Collection.Collection",
        sidebarProps: {
          displayText: "Collection",
          icon: <InventoryIcon />,
        },
      },
      {
        path: "/product/collections/add_collection",
        element: <AddCollection />,
        state: "Product.Collection.AddCollection",
      },
      {
        path: "/product/collections/edit_collection/:id",
        element: <EditCollection />,
        state: "Product.Collection.EditCollection",
      },
      {
        path: "/product/skus",
        element: <Sku />,
        state: "Product.Sku.Sku",
        sidebarProps: {
          displayText: "Sku",
          icon: <InventoryIcon />,
        },
      },
      {
        path: "/product/add_skus_details/:sku",
        element: <AddSkuDetailsPage />,
        state: "Product.Sku.add_sku_details",
      },
      {
        path: "/product/skus_details/:sku",
        element: <SkuDetailsPage />,
        state: "Product.Sku.sku_details",
      },
    ],
  },
  {
    path: "/settings",
    element: <MarketingPageLayout />,
    state: "settings",
    sidebarProps: {
      displayText: "Settings",
      icon: <SettingsSuggestIcon />,
    },
    child: [
      {
        path: "/settings/button_link",
        element: <GetAllButtonLink />,
        state: "settings.button_link",
        sidebarProps: {
          displayText: "Button Link",
          icon: <LinkIcon />,
        }
      },
      {
        path: "/settings/button_link/add",
        element: <AddButtonLink />,
        state: "settings.button_link.add",
      },
      {
        path: "/settings/button_link/edit/:id",
        element: <EditButtonLinkPage />,
        state: "settings.button_link.edit",
      },
      {
        path: "/settings/badges",
        element: <BadgeList />,
        state: "Settings.Badge.Badge",
        sidebarProps: {
          displayText: "Badges",
          icon: <InventoryIcon />,
        },
      },
      {
        path: "/settings/badges/add",
        element: <AddBadge />,
        state: "Settings.Badge.AddBadge",
      },
      {
        path: "/settings/badges/edit/:id",
        element: <EditBadge />,
        state: "Settings.Badge.EditBadge",
      },
    ]
  },
  // {
  //   path: "finance",
  //   element: <FinancePage />,
  //   state: "Finance",
  //   sidebarProps: {
  //     displayText: "Finance",
  //     icon: <PersonIcon />,
  //   },
  // },
  {
    path: "orders",
    element: <OrderTable />,
    state: "Orders",
    sidebarProps: {
      displayText: "Orders",
      icon: <ShoppingCartIcon />,
    },
  },
  {
    path: "payments",
    element: <PaymentTable />,
    state: "Payments",
    sidebarProps: {
      displayText: "Payments",
      icon: <PaymentIcon />,
    },
  },
  {
    path: "retrieve_otp",
    element: <RetrieveOtp />,
    state: "RetrieveOTP",
    sidebarProps: {
      displayText: "OTP Request",
      icon: <PasswordIcon />,
    },
  },
];



export default appRoutes;
