import * as React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Typography, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { OrderAdjustingInputField, AMOUNT_PROPERTY, iniOrderAdjustingInputField } from './OrderAdjustmentDto';
import { IStoreCreditWallet } from '../../../../models/IStoreCredit';
import dayjs from 'dayjs';

interface UserLayoutPageProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    updateAdjustWallet: (inputField: OrderAdjustingInputField) => void,
    storeCredits?: IStoreCreditWallet[]; // Ensure this prop is passed correctly
}

const AdjustWallet: React.FC<UserLayoutPageProps> = ({
    open,
    setOpen,
    updateAdjustWallet,
    storeCredits // Ensure this prop is passed from the parent component
}) => {
    const [inputField, setInputField] = React.useState(iniOrderAdjustingInputField());
    const [errors, setErrors] = React.useState({
        card_type: false,
        description: false,
        amount: false,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInputField((prev) => ({
            ...prev,
            [name]: name === AMOUNT_PROPERTY ? Math.max(parseFloat(value) || 0, 0) : value, // Prevent negative numbers
        }));
        setErrors((prev) => ({ ...prev, [name]: !value }));
    };

    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        const { value } = event.target;
        setInputField((prev) => ({
            ...prev,
            card_type: value,
        }));
        setErrors((prev) => ({ ...prev, card_type: !value }));
    };

    const _confirmUpdate = () => {
        const newErrors = {
            card_type: !inputField.card_type,
            description: !inputField.description.trim(),
            amount: !inputField.amount,
        };

        setErrors(newErrors);

        if (Object.values(newErrors).some((error) => error)) {
            return;
        }

        updateAdjustWallet(inputField);
        setInputField(iniOrderAdjustingInputField());
        setOpen(false);
    };

    const _cancelUpdate = () => {
        setInputField(iniOrderAdjustingInputField());
        setOpen(false);
    };

    const handleWheel = (event: WheelEvent) => {
        event.preventDefault(); // Prevent the default scrolling behavior
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.currentTarget.addEventListener('wheel', handleWheel as EventListener); // Attach wheel event on focus
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        event.currentTarget.removeEventListener('wheel', handleWheel as EventListener); // Remove wheel event on blur
    };

    // Extract unique card types from storeCredits
    const nonExpiredCardTypes = storeCredits
        ? storeCredits
            .filter(credit => !credit.expiry || dayjs(credit.expiry).isAfter(dayjs())) // Filter out expired cards if needed
            .map(credit => credit.card_type)
            .filter((value, index, self) => self.indexOf(value) === index) // Ensure unique card types
        : [];

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogTitle>{"Deduct Amount from Wallet"}</DialogTitle>
            <DialogContent>
                <Typography variant="body2" gutterBottom>
                    Please key in the amount to deduct from wallet. Key in positive number for deduction.
                </Typography>

                {/* Dropdown for Card Type */}
                <FormControl fullWidth size="small" margin="normal" error={errors.card_type}>
                    <InputLabel required>Card Type</InputLabel>
                    <Select
                        name="card_type"
                        label="Card Type"
                        value={inputField.card_type || ""}
                        onChange={handleSelectChange}
                        required
                    >
                        {nonExpiredCardTypes.map((cardType, index) => (
                            <MenuItem key={index} value={cardType}>
                                {cardType}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    name="description"
                    label="Description"
                    value={inputField.description}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    margin="normal"
                    type="string"
                    style={{ width: '100%' }}
                    error={errors.description}
                    helperText={errors.description ? "Description is required" : ""}
                />
                <TextField
                    name="amount"
                    label="Amount"
                    value={inputField.amount}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    margin="normal"
                    type="number"
                    style={{ width: '100%' }}
                    onFocus={handleFocus} // Add focus handler
                    onBlur={handleBlur}   // Add blur handler
                    error={errors.amount}
                    helperText={errors.amount ? "Amount is required" : ""}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => _cancelUpdate()} color="primary">
                    Cancel
                </Button>
                <Button 
                    onClick={() => _confirmUpdate()} 
                    variant="contained" 
                    color="primary"
                    disabled={!inputField.card_type || !inputField.description.trim() || !inputField.amount}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AdjustWallet;