import React, { useState } from 'react';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { retrieveOtp } from '../../../api/RetrieveAPI';
import { toast } from '../../common/SnackBar/snackBar';

const RetrieveOtpPage: React.FC = () => {
  const [fullMobile, setFullMobile] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [otp, setOtp] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    setOtp(null);

    try {
      const otpResponse = await retrieveOtp(fullMobile);
      setOtp(otpResponse);
      toast.success('OTP retrieved successfully');
    } catch (error: any) {
      toast.error(error.message || 'Failed to retrieve OTP');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 4, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Retrieve OTP
      </Typography>
      <form onSubmit={handleSubmit}>
        <PhoneInput
            country={'sg'}
            value={fullMobile}
            onChange={(phone) => setFullMobile(phone.startsWith('+') ? phone : `+${phone}`)}
            inputProps={{
                name: 'phone',
                required: true,
                autoFocus: true,
            }}
            containerStyle={{ width: '100%' }}
            inputStyle={{ width: '100%' }}
            />
            <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            fullWidth
            sx={{ mt: 2 }}
            >
            {isSubmitting ? <CircularProgress size={24} /> : 'Retrieve OTP'}
            </Button>
      </form>

      {otp && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6">Your OTP:</Typography>
          <Typography variant="body1">{otp}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default RetrieveOtpPage;