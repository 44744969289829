import React, { useState, useEffect } from "react";
import { TextField, Button, Box } from "@mui/material";
import { IMemberGroup, MemberGroupFormProps } from "../../../models/IMemberGroups";

const MemberGroupForm: React.FC<MemberGroupFormProps> = ({ memberGroupForm, onSave }) => {
  const [form, setForm] = useState<IMemberGroup>(memberGroupForm);
  const [userIDs, setUserIDs] = useState<string[]>([]);

  useEffect(() => {
    setForm(memberGroupForm);
    setUserIDs(
      memberGroupForm.user_ids
        ? memberGroupForm.user_ids.map(String).map(id => (id.endsWith(",") ? id : id + ","))
        : []
    );
  }, [memberGroupForm]);
  

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleUserIDChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserIDs(e.target.value.split("\n").map(id => id.trim()));
  };
  
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
  
      setUserIDs(prev => {
        if (prev.length === 0 || prev[prev.length - 1].trim() === "") {
          return [...prev, ""]; // Add a new empty row if last row is empty
        }
  
        // Ensure the last entry ends with a comma before adding a new line
        const updated = [...prev];
        if (!updated[updated.length - 1].endsWith(",")) {
          updated[updated.length - 1] += ",";
        }
        return [...updated, ""]; // Add a new line
      });
    }
  };  

  const handleSubmit = () => {
    const updatedForm = {
      ...form,
      user_ids: userIDs.map(id => parseInt(id.trim(), 10)).filter(id => !isNaN(id)),
    };
    onSave(updatedForm);
  };

  return (
    <Box component="form" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <TextField
        label="Member Group Name"
        name="name"
        value={form.name}
        onChange={handleChange}
        required
        fullWidth
        size="small"
      />
      <TextField
        label="Description"
        name="description"
        value={form.description}
        onChange={handleChange}
        fullWidth
        size="small"
      />

      <TextField
        label="User IDs"
        name="user_ids"
        value={userIDs.join("\n")} // Display user IDs as column
        onChange={handleUserIDChange}
        fullWidth
        multiline
        size="small"
        placeholder="Enter user IDs (one per line)..."
        inputProps={{ onKeyDown: handleKeyDown }} // ✅ Fix here
      />

      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default MemberGroupForm;
