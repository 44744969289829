import axiosInstance from './axiosInstance';
import { PATH_NAME } from '../configs/pathName';

export const retrieveOtp = async (full_mobile: string): Promise<string> => {
  try {
    const response = await axiosInstance.post(PATH_NAME.API_ADMIN_USER_RETRIEVE_OTP, { full_mobile });
    return response.data.result;
  } catch (error: any) {
    throw new Error(error.response?.data?.error_message || "Failed to retrieve OTP");
  }
};
