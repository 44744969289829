import axiosInstance from '../api/axiosInstance';
import { IUserBasicInfo, IUserMessage } from '../models/IUser';
import { PATH_NAME } from '../configs/pathName';
import { handleError } from '../helpers/Fetchers/fetchers';

export const fetchUsersAPI = async (): Promise<{ result: IUserBasicInfo[] }> => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_USERS);
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching users:');
    throw error;
  }
};

export const getUserByIdAPI = async (id: string): Promise<IUserBasicInfo> => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_USER_BY_ID(id));
    if (response.data.success) {
      return response.data.result;
    } else {
      throw new Error('Failed to fetch user');
    }
  } catch (error) {
    handleError(error, `Error fetching user with ID ${id}:`);
    throw error;
  }
};

export const updateUserAPI = async (id: string, user: IUserBasicInfo): Promise<{ success: boolean; error_message: string | null; result: IUserBasicInfo }> => {
  try {
    const response = await axiosInstance.put(PATH_NAME.API_ADMIN_USER_BY_ID(id), user);
    return response.data;
  } catch (error) {
    handleError(error, 'Error updating user:');
    throw error;
  }
};

export const deleteUserAPI = async (id: number) => {
  try {
    await axiosInstance.delete(PATH_NAME.API_ADMIN_USER_BY_ID(id.toString()));
  } catch (error) {
    handleError(error, `Error deleting role with ID ${id}:`);
    throw error;
  }
};

export const sentSmsAPI = async (payload: {
  customer_id: string | undefined;
  message: string
}) => {
  try {
    const response = await axiosInstance.post(`${PATH_NAME.API_ADMIN_USERS}send_sms`, payload);
    return response.data;
  } catch (error) {
    handleError(error, 'send_sms fail');
    throw error;
  }
};

export const userAdjustWalletAPI = async (payload: {
  customer_id: string | undefined,
  amount: number,
  description: string,
  card_type:string,
}) => {
  try {
    const response = await axiosInstance.post(`${PATH_NAME.API_ADMIN_USERS}adjust_wallet`, payload);
    return response.data;
  } catch (error) {
    handleError(error, 'send_sms fail');
    throw error;
  }
};

export const getUserMessagebyUserId = async (user_id: number): Promise<IUserMessage[]> => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_USER_GET_MESSAGES(user_id));
    if (response.data.success) {
      return response.data.result.items as IUserMessage[];
    } else {
      throw new Error('Failed to fetch user');
    }
  } catch (error) {
    handleError(error, `Error fetching user with ID ${user_id}:`);
    throw error;
  }
};

export const UserSearchAPI = async (key: string) => {
  try {
    const response = await axiosInstance.get(key);
    return response.data;
  } catch (error) {
    handleError(error, 'fetching items');
    throw new Error('Failed to fetch items');
  }
};