import * as React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Typography, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { UserAdjustingInputField, iniUserAdjustingInputField } from './UserAdjustmentDto';
import dayjs from 'dayjs';
import { IStoreCreditWallet } from '../../../../../models/IStoreCredit';

interface UserLayoutPageProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    updateAdjustWallet: (inputField: UserAdjustingInputField) => void,
    storeCredits?: IStoreCreditWallet[];
}

const UserAdjustWallet: React.FC<UserLayoutPageProps> = ({
    open,
    setOpen,
    updateAdjustWallet,
    storeCredits
}) => {
    const [inputField, setInputField] = React.useState(iniUserAdjustingInputField());
    const [errors, setErrors] = React.useState({
        card_type: false,
        description: false,
        amount: false,
    });

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const { name, value } = event.target;
        setInputField((prev) => ({
            ...prev,
            [name as string]: value,
        }));
        setErrors((prev) => ({ ...prev, [name as string]: !value }));
    };

    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        const { value } = event.target;
        setInputField((prev) => ({
            ...prev,
            card_type: value,
        }));
        setErrors((prev) => ({ ...prev, card_type: !value }));
    };
    

    const _confirmUpdate = () => {
        const newErrors = {
            card_type: !inputField.card_type,
            description: !inputField.description.trim(),
            amount: !inputField.amount,
        };

        setErrors(newErrors);

        if (Object.values(newErrors).some((error) => error)) {
            return;
        }

        updateAdjustWallet(inputField);
        setInputField(iniUserAdjustingInputField());
        setOpen(false);
    };

    const _cancelUpdate = () => {
        setInputField(iniUserAdjustingInputField());
        setOpen(false);
    };

    const handleWheel = (event: WheelEvent) => {
        event.preventDefault();
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.currentTarget.addEventListener('wheel', handleWheel as EventListener);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        event.currentTarget.removeEventListener('wheel', handleWheel as EventListener);
    };

    const nonExpiredCardTypes = storeCredits
        ? storeCredits
            .filter(credit => !credit.expiry || dayjs(credit.expiry).isAfter(dayjs()))
            .map(credit => credit.card_type)
            .filter((value, index, self) => self.indexOf(value) === index)
        : [];

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>{"Adjust Wallet"}</DialogTitle>
            <DialogContent>
                <Typography variant="body2" gutterBottom>
                    Please put a positive number to add to the wallet and a negative number to deduct from the wallet.
                </Typography>
                
                <FormControl fullWidth size="small" margin="normal" error={errors.card_type}>
                    <InputLabel required>Card Type</InputLabel>
                    <Select
                        name="card_type"
                        label="Card Type"
                        value={inputField.card_type || ""}
                        onChange={handleSelectChange}
                        required
                    >
                        {nonExpiredCardTypes.map((cardType, index) => (
                            <MenuItem key={index} value={cardType}>
                                {cardType}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    name="description"
                    label="Description"
                    value={inputField.description}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    margin="normal"
                    required
                    error={errors.description}
                    helperText={errors.description ? "Description is required" : ""}
                />

                <TextField
                    name="amount"
                    label="Amount"
                    value={inputField.amount}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    margin="normal"
                    type="number"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    required
                    error={errors.amount}
                    helperText={errors.amount ? "Amount is required" : ""}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={_cancelUpdate} color="primary">Cancel</Button>
                <Button 
                    onClick={_confirmUpdate} 
                    variant="contained" 
                    color="primary" 
                    disabled={!inputField.card_type || !inputField.description.trim() || !inputField.amount}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserAdjustWallet;
